import { graphql, Link } from "gatsby"
import React from "react"
import { CustomBloc } from "../components/CustomBloc"
import SEO from "../components/seo"
import { Title } from "../components/Title"
import { ImageTitleDesc } from "../components/ImageTitleDesc"
import { getTheme } from "../theme"
import { Card } from "antd"
import { Flex } from "../components/Flex"


const Guides = ({ data }) => {
  const items = data.allPrismicGuide.edges.map(edge => {
    const item = edge.node.data.guide_block[0]
    return {
      title: item.block_title.text,
      imgUrl: item.block_image.url,
      uid: edge.node.uid
    }
  })

  return (
    <div style={{
      background: getTheme().primaryBackgroundColor07,
    }}>
      <SEO title={`guides`} />
      <CustomBloc contentStyle={{ justifyContent: 'flex-start' }} >
        <div>
          <Title>{`Guides Pratiques`}</Title>
          <Flex style={{ flexWrap: 'wrap' }}>

            {items
              .map((item) => {
                const title = item.title
                const urlLink = `/guides-pratiques/${item.uid}`
                return (
                  <Card
                    key={`${title}`}
                    hoverable={false}
                    bordered={false}
                    style={{
                      margin: 10,
                      minWidth: '320px',
                      width: '30%',
                      background: getTheme().primaryBackgroundColor07,
                    }}
                  >
                    <Link to={urlLink}>
                      <ImageTitleDesc
                        descriptionStyle={{ minHeight: 'unset' }}
                        title={`${title}`}
                        src={item.imgUrl} // Fluid not available. why?
                        // @ts-ignore
                        imageHeight={220}
                        // @ts-ignore
                        imageWidth={220}
                      />
                    </Link>
                  </Card>
                )
              })}

          </Flex>

        </div>
      </CustomBloc>
    </div >

  )
}

export default Guides

export const query = graphql`
query GuidesQuery {
  allPrismicGuide {
    edges {
      node {
        data {
          guide_block {
            block_title {
              text
            }
            block_image {
              url
            }
          }
        }
        uid
      }
    }
  }
}
`